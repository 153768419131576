@charset "utf-8";

@import "minimal-mistakes/skins/dark"; // skin
@import "minimal-mistakes";

html {
    font-size: 14px; // change to whatever

    @include breakpoint($medium) {
      font-size: 14px; // change to whatever
    }

    @include breakpoint($large) {
      font-size: 17px; // change to whatever
    }

    @include breakpoint($x-large) {
      font-size: 18px; // change to whatever
    }
  }
